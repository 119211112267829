import React, { useState, useEffect, useRef } from "react";
import { Snackbar } from "@mui/material";

const RunnerGame = () => {
  const canvasRef = useRef(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Game constants
  const GRAVITY = 0.6;
  const JUMP_FORCE = -12;
  const GAME_SPEED = 5;
  const OBSTACLE_SPAWN_RATE = 0.02;
  const POWERUP_SPAWN_RATE = 0.005;

  // Game state
  const gameState = useRef({
    player: {
      x: 50,
      y: 0,
      width: 40,
      height: 40,
      velocityY: 0,
      isJumping: false,
      hasShield: false,
    },
    obstacles: [],
    powerUps: [],
    ground: 0,
    frameCount: 0,
    speed: GAME_SPEED,
  });

  // Initialize canvas and game
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Set canvas size
    canvas.width = 800;
    canvas.height = 300;
    gameState.current.ground = canvas.height - 60;
    gameState.current.player.y =
      gameState.current.ground - gameState.current.player.height;

    // Draw initial frame
    drawGame(ctx);

    // Handle keyboard events
    const handleKeyDown = (e) => {
      if (
        (e.code === "Space" || e.code === "ArrowUp") &&
        !gameState.current.player.isJumping
      ) {
        jump();
      }
      if (!gameStarted && !gameOver) {
        startGame();
      }
      if (gameOver && (e.code === "Space" || e.code === "Enter")) {
        resetGame();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStarted, gameOver]);

  // Game loop
  useEffect(() => {
    let animationId;

    const gameLoop = () => {
      if (!gameStarted || gameOver) return;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      updateGame();
      drawGame(ctx);
      animationId = requestAnimationFrame(gameLoop);
    };

    if (gameStarted && !gameOver) {
      animationId = requestAnimationFrame(gameLoop);
    }

    return () => cancelAnimationFrame(animationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStarted, gameOver]);

  const startGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setScore(0);
    gameState.current = {
      ...gameState.current,
      obstacles: [],
      powerUps: [],
      frameCount: 0,
      speed: GAME_SPEED,
    };
  };

  const resetGame = () => {
    setGameStarted(false);
    setGameOver(false);
    gameState.current.player.y =
      gameState.current.ground - gameState.current.player.height;
    gameState.current.player.velocityY = 0;
    gameState.current.player.isJumping = false;
    gameState.current.player.hasShield = false;
  };

  const jump = () => {
    if (!gameState.current.player.isJumping) {
      gameState.current.player.velocityY = JUMP_FORCE;
      gameState.current.player.isJumping = true;
    }
  };

  const updateGame = () => {
    const state = gameState.current;
    state.frameCount++;

    // Update player
    state.player.velocityY += GRAVITY;
    state.player.y += state.player.velocityY;

    // Ground collision
    if (state.player.y > state.ground - state.player.height) {
      state.player.y = state.ground - state.player.height;
      state.player.velocityY = 0;
      state.player.isJumping = false;
    }

    // Spawn obstacles
    if (Math.random() < OBSTACLE_SPAWN_RATE) {
      state.obstacles.push({
        x: canvasRef.current.width,
        width: 20 + Math.random() * 30,
        height: 40 + Math.random() * 40,
      });
    }

    // Spawn power-ups
    if (Math.random() < POWERUP_SPAWN_RATE) {
      state.powerUps.push({
        x: canvasRef.current.width,
        y: state.ground - 100 - Math.random() * 100,
        type: Math.random() < 0.5 ? "shield" : "score",
      });
    }

    // Update obstacles
    state.obstacles = state.obstacles.filter((obstacle) => {
      obstacle.x -= state.speed;

      // Collision detection
      if (
        !state.player.hasShield &&
        obstacle.x < state.player.x + state.player.width &&
        obstacle.x + obstacle.width > state.player.x &&
        state.player.y + state.player.height > state.ground - obstacle.height
      ) {
        handleGameOver();
      }

      return obstacle.x > -obstacle.width;
    });

    // Update power-ups
    state.powerUps = state.powerUps.filter((powerUp) => {
      powerUp.x -= state.speed;

      // Collision detection
      if (
        powerUp.x < state.player.x + state.player.width &&
        powerUp.x + 20 > state.player.x &&
        powerUp.y < state.player.y + state.player.height &&
        powerUp.y + 20 > state.player.y
      ) {
        handlePowerUp(powerUp.type);
        return false;
      }

      return powerUp.x > -20;
    });

    // Increase score
    setScore((prev) => prev + 1);

    // Increase speed gradually
    if (state.frameCount % 500 === 0) {
      state.speed += 0.5;
    }
  };

  const handleGameOver = () => {
    setGameOver(true);
    if (score > highScore) {
      setHighScore(score);
      setSnackbarMessage(`🎉 New High Score: ${score}! 🎉`);
    } else {
      setSnackbarMessage(`Game Over! Score: ${score}`);
    }
    setSnackbarOpen(true);
  };

  const handlePowerUp = (type) => {
    if (type === "shield") {
      gameState.current.player.hasShield = true;
      setTimeout(() => {
        gameState.current.player.hasShield = false;
      }, 5000);
      setSnackbarMessage("🛡️ Shield activated!");
    } else {
      setScore((prev) => prev + 500);
      setSnackbarMessage("⭐ +500 points!");
    }
    setSnackbarOpen(true);
  };

  const drawGame = (ctx) => {
    const state = gameState.current;

    // Clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Draw sky gradient
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    gradient.addColorStop(0, "#87CEEB");
    gradient.addColorStop(1, "#E0F6FF");
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Draw ground
    ctx.fillStyle = "#8B4513";
    ctx.fillRect(
      0,
      state.ground,
      ctx.canvas.width,
      ctx.canvas.height - state.ground
    );

    // Draw grass
    ctx.fillStyle = "#228B22";
    ctx.fillRect(0, state.ground, ctx.canvas.width, 10);

    // Draw player
    ctx.fillStyle = state.player.hasShield ? "#4CAF50" : "#FF5722";
    ctx.fillRect(
      state.player.x,
      state.player.y,
      state.player.width,
      state.player.height
    );

    // Draw shield effect
    if (state.player.hasShield) {
      ctx.strokeStyle = "#4CAF50";
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.arc(
        state.player.x + state.player.width / 2,
        state.player.y + state.player.height / 2,
        state.player.width * 0.7,
        0,
        Math.PI * 2
      );
      ctx.stroke();
    }

    // Draw obstacles
    ctx.fillStyle = "#d32f2f";
    state.obstacles.forEach((obstacle) => {
      ctx.fillRect(
        obstacle.x,
        state.ground - obstacle.height,
        obstacle.width,
        obstacle.height
      );
    });

    // Draw power-ups
    state.powerUps.forEach((powerUp) => {
      ctx.fillStyle = powerUp.type === "shield" ? "#4CAF50" : "#FFD700";
      ctx.beginPath();
      ctx.arc(powerUp.x + 10, powerUp.y + 10, 10, 0, Math.PI * 2);
      ctx.fill();
    });

    // Draw score
    ctx.fillStyle = "#000";
    ctx.font = "20px Arial";
    ctx.fillText(`Score: ${score}`, 20, 30);
    ctx.fillText(`High Score: ${highScore}`, 20, 60);

    // Draw game over or start message
    if (!gameStarted || gameOver) {
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = "#fff";
      ctx.font = "30px Arial";
      ctx.textAlign = "center";
      const message = gameOver
        ? "Game Over! Press SPACE to restart"
        : "Press SPACE to start";
      ctx.fillText(message, ctx.canvas.width / 2, ctx.canvas.height / 2);
      ctx.textAlign = "left";
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Runner Game</h1>
      <p>
        Press SPACE or UP ARROW to jump. Collect power-ups and avoid obstacles!
      </p>
      <canvas
        ref={canvasRef}
        style={{
          border: "2px solid #000",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
        tabIndex="0"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default RunnerGame;
